import React, { useState } from 'react';
import './login.css'
import { Link, useNavigate } from 'react-router-dom';
import logo from './modelverse-logo.png'


const Login = (props) => {

    const BASE_URL = "http://xyz.modelverse.in"

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const userData = {
        email: email,
        password: password,
    };

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
    };

    const handleLogin = () => {
        // Implement login logic here

        if (password === '' || email === '') {
            alert('Please Enter Required details in All Fields')
            return;
        }

        fetch(`${BASE_URL}/login`, requestOptions)
            .then((response) => {
                
                return response.json(); // Parse the response JSON
            })
            .then((json) => {
                // Handle the API response data here
                if (json.success === true) {
                   
                    localStorage.setItem("json",json)
                    localStorage.setItem('userToken', json.token);
                    localStorage.setItem('email',json.email)
                    localStorage.setItem('credits',json.credits)
                    localStorage.setItem('userName', json.username);
                   
                  
                    navigate('/inp');
                }
                else {
                    alert(json.message);
                  
                }
            })
            .catch((error) => {
                // console.error('API Error:', error);
            });

        
    };

    return (
        <div className='login-container'>
            <div className='card'>
                <img src={logo} className='logo-image' alt="" />
                <h2>Login</h2>
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button className='login-btn' onClick={handleLogin}>Login</button>
                <p className='register-link'>
                    Don't have an account? <Link to='/signup'>Register Now</Link> 
                </p>
            </div>
        </div>
    );
};

export default Login;
