import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import AvatarEditor from 'react-avatar-editor';
import './createimage.css';
import { useLocation ,useNavigate} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import Profile from './Profile';


const ThemesComponent = () => {
  const navigate = useNavigate();
  const [toggleRecent ,setToggleRecent]=useState(false)
  
  const location = useLocation();
  const imageUrlTemp = location.state?.key;
  // setImageUrl1(imageUrlTemp)

  const [imageUrl1, setImageUrl1] = useState(imageUrlTemp);
   
  // useEffect(()=>{
  //   const data = location.state;
  
  //   console.log(data);
  // },[])

  useEffect(()=>{
    console.log("hello",imageUrl1,imageUrlTemp)
    if(!imageUrl1||imageUrl1==null||imageUrl1===undefined){
      console.log("hello")
       navigate('/inp')
    }
  },[])
  
  const [dataArray, setDataArray] = useState(Array(4).fill(''));

  // Function to set all elements in the array to the given data
  const handleSetData = (data) => {
    setDataArray(Array(4).fill(data));
  };
  const [themes, setThemes] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState("Surprise me");
  const [generatedImages, setGeneratedImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isZoomed, setIsZoomed] = useState(false);
  const [selectedButton, setSelectedButton] = useState(null);
  const [selectedImageStyle, setSelectedImageStyle] = useState({});
  const [rotationAngle, setRotationAngle] = useState(0);
  const [description, setDescription] = useState('');
  const [showDescription, setShowDescription] = useState(false);
  const [showThemes, setShowThemes] = useState(true);
  const [scale, setScale] = useState(1);
  const [showGallery, setShowGallery] = useState(false);
  const editorRef = useRef(null);
  const [credits, setCredits] = useState(0);

  const fetchCredits = async (email) => {
    try {
      const response = await axios.post("http://xyz.modelverse.in/credits", { email });
      console.log(response.data.credits);
      setCredits(response.data.credits); // Ensure you're setting the correct data
    } catch (error) {
      // console.error('Error fetching credits:', error);
    }
  };
  const email=localStorage.getItem('email');
  useEffect(()=>{
    const email=localStorage.getItem('email');
    fetchCredits(email)
  },[])

  useEffect(() => {
    const fetchThemes = async () => {
      try {
        const response = await axios.post("http://xyz.modelverse.in/themes");
        console.log(response.data)
        setThemes(response.data);
      } catch (error) {
        console.error('Error fetching themes:', error);
      }
    };

    fetchThemes();
  }, []);
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.post("http://xyz.modelverse.in/fetchimage");
        const imageUrls = response.data;
        setGeneratedImages(imageUrls.reverse());
      } catch (error) {
        console.error('Error fetching images from gallery:', error);
      }
    };
  
    fetchImages();
  }, [generatedImages]);
  
  useEffect(() => {
    const calculateInitialScale = () => {
      const container = document.querySelector('.image-upload-container1');
      if (container && selectedImage) {
        const containerWidth = container.clientWidth;
        const containerHeight = container.clientHeight;
        const image = new Image();
        image.onload = () => {
          const imageWidth = image.naturalWidth;
          const imageHeight = image.naturalHeight;
          const scaleToFitWidth = (containerWidth * 0.7) / imageWidth;
          const scaleToFitHeight = (containerHeight * 0.7) / imageHeight;
          const initialScale = Math.min(scaleToFitWidth, scaleToFitHeight);
          setScale(initialScale);
        };
        image.src = URL.createObjectURL(selectedImage);
      }
    };
  
    if (selectedImage) {
      calculateInitialScale();
    }
  }, [selectedImage]);
  

  const handleThemeSelect = (theme) => {
    setSelectedTheme(theme);
    setSelectedButton(theme);
    setShowThemes(true);
    setShowDescription(false);
  };

  // useEffect(()=>{
  //      handleConvertUrlToFile()
  // },[])

  // const handleConvertUrlToFile = async () => {
  //   try {
      
  //     const blob = await imageUrlTemp.blob();
      
  //     // Create a File object with metadata
  //     const file = new File([blob], 'Image.jpg', {
  //       type: blob.type,
  //       lastModified: Date.now(),
  //     });
  //     console.log(file," ",file[0])
  //     // setSelectedImage(file)
  //     console.log('File created:', file);
  //   } catch (error) {
  //     console.error('Error converting URL to File:', error);
  //   }
  // };
  
  const handleImageSelect = (event) => {
    console.log(event.target.files[0]," data")
    setSelectedImage(event.target.files[0]);
    // setSelectedImage(imageUrlTemp)
    setSelectedButton(null);
    const imageInput = document.getElementById("image-input");
    if (imageInput) {
      imageInput.disabled = true;
    }
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleDescriptionSubmit = () => {
    setDescription('');
  };

  const handleGenerateImage = async () => {
    try {
      // if (!selectedImage || !selectedTheme) {
      //   alert('Please select both an image and a theme.');
      //   return;
      // }

      if (!editorRef.current) {
        alert('Please select an image and set its scale.');
        return;
      }

      const canvas = editorRef.current.getImageScaledToCanvas();
      const imageData = canvas.toDataURL('image/png'); 
      const email=localStorage.getItem('email');
      const formData = new FormData();
      formData.append('image', imageData);
      formData.append('theme', selectedTheme);
      formData.append('description', description);
      formData.append('position',position)
      formData.append('email',email)


      const response = await axios.post("http://xyz.modelverse.in/create", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      const imageUrl = `http://xyz.modelverse.in/${response.data.url}`;
      console.log(response.data," response data",imageUrl)
      setCredits(response.data.credits)
      handleSetData(response.data.url)
      setToggleRecent(true)
    } catch (error) {
      console.error('Error generating image:', error);
    }
  };

  const toggleZoom = () => {
    setIsZoomed(!isZoomed);
  };

  const handleRotateLeft = () => {
    setRotationAngle(angle => angle - 90);
  };

  const handleRotateRight = () => {
    setRotationAngle(angle => angle + 90);
  };

  const handleScaleChange = (event) => {
    setScale(parseFloat(event.target.value));
  };

  useEffect(() => {
    setSelectedImageStyle({
      ...selectedImageStyle,
      transform: `rotate(${rotationAngle}deg)`,
    });
  }, [rotationAngle]);

  const handleGalleryToggle = async () => {
    try {
      setShowGallery(!showGallery);
      
      const response = await axios.post("http://xyz.modelverse.in/fetchimage");
      const imageUrls = response.data;
      
      console.log(imageUrls)
      setGeneratedImages(imageUrls);
    } catch (error) {
      console.error('Error fetching images from gallery:', error);
    }
  };

  const handleDownload=async(url)=>{
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      
      // Create a URL for the blob
      const blobUrl = window.URL.createObjectURL(blob);
      
      // Create a temporary link element
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = url.substring(url.lastIndexOf('/') + 1); // Extract filename from URL
      document.body.appendChild(link);
      
      // Trigger the download
      link.click();
      
      // Clean up
      document.body.removeChild(link);
  } catch (error) {
      console.error('Error downloading file:', error);
  }
  }

  const [position, setPosition] = useState({ x: 0, y: 0 });

  // const handlePositionChange = (newPosition) => {
  //   setPosition(newPosition);
  //   console.log('Image position:', newPosition);
  // };

  const handlePositionChange = (newPosition) => {
    const minX = -100;
    const maxX = 250;
    const minY = -60;
    const maxY = 200;

    const adjustedX = Math.round(newPosition.x * (maxX - minX) + minX);
    const adjustedY = Math.round(newPosition.y * (maxY - minY) + minY);

    setPosition(newPosition);
    console.log('Image position1 :', { x: adjustedX, y: adjustedY });
  };

  return (
    <>
   {imageUrl1 && <div className="themes-container">
      <Profile credit={credits}/>
      <div className="sidebar">
        <div className="themes-heading">
          <a href="#" style={{ marginLeft: "1vw" }} onClick={() => { setShowThemes(true); setShowDescription(false); }}>Themes</a>
          <a href="#" style={{ marginLeft: "5vw" }} onClick={() => { setShowDescription(true); setShowThemes(false); }}>Description</a>
        </div>
        {showThemes && (
          <ul className="theme-list">
            {themes.map(theme => (
              <li key={theme.id} className="theme-item">
                <button
                  onClick={() => handleThemeSelect(theme.label)}
                  className="theme-button"
                  style={{
                    border: selectedButton === theme.label ? '1px solid black' : 'none',
                    outline: 'none',
                    cursor: 'pointer'
                  }}
                >
                  {theme.thumbnail && <img src={theme.thumbnail} alt={theme.label} className="theme-image" />}
                  {theme.label}
                </button>
              </li>
            ))}
          </ul>
        )}
        {showDescription && (
          <div className="description-input">
            <input
              type="text"
              value={description}
              onChange={handleDescriptionChange}
              placeholder="Enter Description"
            />
            <button onClick={handleDescriptionSubmit}>Submit Description</button>
          </div>
        )}
      </div>
      <div className="main-content">
        <div className="image-upload-container1">
          <label htmlFor="image-input" className="image-label" onClick={toggleZoom}>
            {/* {selectedImage ? ( */}
            {imageUrl1 ? (
              <AvatarEditor
                // ref={editorRef}
                // image={URL.createObjectURL(selectedImage)}
               /* image={imageUrl1}
                width={400} 
                height={400} 
                border={0} 
                color={[255, 255, 255, 0.6]}
                scale={scale}
                rotate={rotationAngle}
                onImageChange={() => {
                  const newImage = editorRef.current.getImage();
                  console.log('New image data:', newImage);
                }}*/
                  ref={editorRef}
                image={imageUrl1}
                width={400}
                height={400}
                border={0}
                color={[255, 255, 255, 0.6]}
                scale={scale}
                rotate={rotationAngle}
                position={position}
                onPositionChange={handlePositionChange}

              />
            ) : (
              {/* <div className="upload-instructions">
                <p>Upload a photo of your removed background product</p>
                <p>Drop your image here or click to select an image</p>
              </div> */}
              
            )}
          </label>
          {/* <input
            id="image-input"
            type="file"
            accept="image/*"
            onChange={handleImageSelect}
            style={{ display: 'none' }}
            className='input4image'
          /> */}
        </div>
        <div className="scale-container">
          <input
            type="range"
            min="0.1"
            max="2"
            step="0.01"
            value={scale}
            onChange={handleScaleChange}
          />
        </div>
        <div className="rotate-buttons">
          <button onClick={handleRotateLeft} className="rotate-left">Rotate Left</button>
          <button onClick={handleRotateRight} className="rotate-right">Rotate Right</button>
        </div>
        <button onClick={handleGenerateImage} className="generate-button">Generate Image</button>
       {toggleRecent && <div className="recents">
          <div className="recents-container">
            {/* {generatedImages.map((imageUrl, index) => (
              <div key={index} className="response-image-container">
                <a href={imageUrl} download={`generated_image_${index}.png`}>
                <img src={`http://localhost:3001/${imageUrl}`} alt={`Image ${index}`} />
                </a>
              </div>
            ))} */}

            { dataArray && dataArray.slice(0, 4).map((imageUrl, index) => (
  <div key={index} className="response-image-container">
    <div className='overlay'></div>
    {/* <a href={imageUrl} download={`generated_image_${index}.png`}> */}
      <img src={`http://xyz.modelverse.in/${imageUrl}`} alt={`Image ${index}`} />

      <div className='popUp-section' onClick={()=>{handleDownload(`http://xyz.modelverse.in/${imageUrl}`)}}>
      <FontAwesomeIcon className='fa-2x' icon={faDownload} /><span>Download</span>
      </div>
      
    {/* </a> */}    
  </div>
))}

          </div>

          

        </div>}
      </div>
    </div>}
    </>
  );
};

export default ThemesComponent;
