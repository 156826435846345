import React, { useEffect, useState } from 'react';
import './input.css';
import axios from "axios"

import { useNavigate } from 'react-router-dom';
import Profile from './Profile';

const AddNewAsset = () => {
  const navigate = useNavigate();
  const [credits, setCredits] = useState(0);

  const [imageFile, setImageFile] = useState(null);
  const [productName, setProductName] = useState('');
  const [backgroundRemovedImage, setBackgroundRemovedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

    const fetchCredits = async (email) => {
    try {
      const response = await axios.post("http://xyz.modelverse.in/credits", { email });
      console.log(response.data.credits);
      setCredits(response.data.credits); // Ensure you're setting the correct data
    } catch (error) {
      // console.error('Error fetching credits:', error);
    }
  };
  const email=localStorage.getItem('email');
  useEffect(()=>{
    const email=localStorage.getItem('email');
    fetchCredits(email)
  },[])

  const handleImageUpload = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleProductNameChange = (e) => {
    setProductName(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const email=localStorage.getItem('email');
    if (imageFile) {
      const formData = new FormData();
      formData.append('image', imageFile);
      formData.append('email',email)
      try {
        const response = await fetch('http://xyz.modelverse.in/remove-background', {
          method: 'POST',
          body: formData,
        });
  
        if (response.ok) {
          const d1=await response.json()
          setCredits(d1.credits)
          // const blob = await d1.image.blob();
          const base64String = d1.image;
          const byteCharacters = atob(base64String);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          // Create a Blob from the byte array
          const blob = new Blob([byteArray], { type: 'image/png' });

          const url = window.URL.createObjectURL(blob);
  
          console.log('Generated URL:', url);
  
          const data = { key: url };
          console.log('Data to be sent:', data);
  
          navigate('/create', { state: data });
  
          console.log("after"); // Should print if navigation is successful
        } else {
          console.error('Failed to send image');
          throw new Error('Failed to send image');
        }
      } catch (error) {
        console.error('Error sending image:', error);
      }
    } else {
      console.error('No image selected');
    }
  };
  
  

  return (
    <div className="container">
    <Profile credit={credits}/>
      <div className="header">
        <h2>Add new asset</h2>
      </div>
      <div className="main-container">
        <div className="image-upload">
          <label htmlFor="image-input">
            <div className="image-upload-container">
              {imageFile ? (
                <img src={URL.createObjectURL(imageFile)} alt="Product" />
              ) : backgroundRemovedImage ? (
                <img src={backgroundRemovedImage} alt="Background Removed" />
              ) : (
                <div className="upload-instructions">
                  <p>Upload a photo of your product</p>
                  <p>Aidobly can automatically remove the background from your photo</p>
                  <p>Drop your image here or click to select an image</p>
                </div>
              )}
            </div>
          </label>
          <input
            id="image-input"
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            style={{ display: 'none' }}
          />
        </div>
        <div className="sidebar">
          <div className="product-name">
            <input
              type="text"
              placeholder="Product Name"
              value={productName}
              onChange={handleProductNameChange}
            />
          </div>
          <button type="submit" className="save-asset" onClick={handleSubmit}>
            Save asset
          </button>
        </div>
      </div>
      {imageUrl && (
        <div>
          <h3>Background Removed Image:</h3>
          <img src={imageUrl} alt="Background Removed" />
        </div>
      )}
    </div>
  );
};

export default AddNewAsset;