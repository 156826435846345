import React,{useEffect,useState} from 'react'
import './profile.css'

export default function Profile(props) {
    const [username,setUsername]=useState("User")
    const [credits,setCredits]=useState(0)
    const [email,setEmail]=useState("")
    const [isProfileVisible, setIsProfileVisible] = useState(false);

    useEffect(() => {
        // Fetch the token from localStorage
        const data1 = localStorage.getItem('email');
        const data2 = localStorage.getItem('credits');
        const data3 = localStorage.getItem('userName');
        setEmail(data1)
        setCredits(data2)
        setUsername(data3)
      }, []);

      const toggleProfile = () => {
        setIsProfileVisible(!isProfileVisible);
      };

      const logout = () => {
        localStorage.clear();
        window.location.reload();
      };

  return (
    <div className="profile">
        <button onClick={toggleProfile} className="profile-button">
          {username[0].toUpperCase()}
        </button>
        {isProfileVisible && (
          <div className="profile-dropdown">
            <p><strong>Username:</strong>{username}</p>
            <p><strong>Email:</strong>{email}</p>
            <p><strong>Credits:</strong>{props.credit}</p>
            <div className="gallaLog">
            <button onClick={logout} className="logout-button">Logout</button>
            </div>
          </div>
        )}
      </div>
  )
}
