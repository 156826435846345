import logo from './logo.svg';
import Createimage from "./createimage.jsx";
import { Routes, Route } from "react-router-dom";
import Input from "./input.jsx"
import Login from './login.js';
import Signup from './signup.js';
import ProtectedRoute from './ProtectedRoute.js';
function App() {
  return (
    <div className="App">
      <Routes>
      
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        
        <Route path="/create"
        element={<ProtectedRoute element={Createimage} />}
         />
        <Route path="/inp" element={<ProtectedRoute element={Input} />} />
      </Routes>
    </div>
  );
}

export default App;
