import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from './modelverse-logo.png'
import "./login.css";

const Signup = (props) => {
    const BASE_URL = "http://xyz.modelverse.in";
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const[alertcreate,setalertcreate]=useState("");
    const validateUsername = (username, email) => {
        const usernamePattern = /^(?=.*[0-9])[^\s]+$/;
        const emailLocalPart = email.split('@')[0];
        return usernamePattern.test(username) && !username.includes(emailLocalPart);
    };

    const validatePassword = (password) => {
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return passwordPattern.test(password);
    };

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const handleSendData = () => {
        if (!validateEmail(email)) {
            setalertcreate('Please enter a valid email address.');
            return;
        }

        if (!validateUsername(username, email)) {
            setalertcreate('Username must not contain spaces, must include at least one number, and must not contain any part of the email.');
            return;
        }

        if (!validatePassword(password)) {
            setalertcreate('Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.');
            return;
        }

        const userData = { username, email, password };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(userData),
        };
        // console.log(userData)

        fetch(`${BASE_URL}/signup`, requestOptions)
            .then((response) => response.json())
            .then((json) => {
                if (json.success === true) {
                    navigate('/');
                } else {
                    console.log(json);
                    alert(json.message);
                }
            })
            .catch((error) => {
                console.error('API Error:', error);
            });
    };

   

    return (
        <div className='signup-container'>
            <div className='card'>
                <img src={logo} className='logo-image' alt="" />
                <h2>Sign up</h2>
                <h2 className='alert'>{alertcreate}</h2>
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button className='login-btn' onClick={handleSendData}>SignUp</button>
                
                <p className='register-link'>
                    Already have an account. <Link to='/' style={{ color: '#fe5816' }}>Sign In Now</Link>
                </p>
            </div>
        </div>
    );
};

export default Signup;
